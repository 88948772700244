<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <div
            class="footer"
            v-if="refundStatus===1"
        >
            <linker
                to="/buyer/home"
                class="btn cart"
            >
                <div>
                    <i class="f-icon fs-more">&#xf003;</i>
                    <div class="fs-small">
                        个人中心
                    </div>
                </div>
            </linker>
            <div
                class="btn btn-main"
                @click="onCancelApply"
            >
                取消申请
            </div>
        </div>
        <div
            class="footer"
            v-if="refundStatus===6"
        >
            <refund-express-form-popup
                class="btn btn-main"
                :id="refund.id"
                @click="onCancelApply"
                @submitSuccess="onSubmitSuccess"
            >
                填写物流信息
            </refund-express-form-popup>
        </div>
        <container ref="container">
            <!-- 售后状态：1:待审核; 2:待打款; 3:已退款; 4:已关闭; 5:已取消; 6:待退货; 7:待收货 -->
            <refund-status :data="refund" />
            <list class="ma-b">
                <OrderGoodsItem
                    v-for="(item,index) in order.goodsList"
                    :key="index"
                    :data="item"
                    no-can-refund
                />
                <list-item label=" ">
                    <div
                        class="extra"
                        slot="extra"
                    >
                        订单合计: <span class="price">{{ order.payableAmount | price }}</span>
                    </div>
                </list-item>
            </list>

            <list class="ma-t">
                <list-item
                    label="下单人："
                    :extra="order.buyerMobile"
                >
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <span>{{ order.buyerNickName }}</span>&nbsp;
                        <Linker
                            class="tc-blue"
                            :to="`tel://${order.buyerMobile}`"
                        >
                            <Icon code="&#xf072;" />{{ order.buyerMobile }}
                        </Linker>
                    </div>
                </list-item>
                <list-item label="退款金额：">
                    <div
                        slot="extra"
                        class="extra price"
                    >
                        {{ refund.refundAmount | price }}
                    </div>
                </list-item>
                <list-item
                    label="退款数量："
                    :extra="refund.refundQuantity"
                    v-if="refund.refundQuantity"
                />
                <list-item
                    label="退款类型："
                    :extra="refund.orderRefundTypeName"
                />
                <list-item
                    label="退款原因："
                    :extra="refund.refundReasonName"
                />
                <list-item
                    label="退款说明："
                    :extra="refund.refundDesc"
                />
                <list-item
                    extra="退款凭证："
                    class="reverse"
                >
                    <img-list
                        class="label"
                        slot="label"
                        :data="refund.refundAttachmentUrlList"
                    />
                </list-item>
            </list>
            <!--审核信息-->
            <list
                class="ma-t"
                v-if="refundStatus!==1 && refundStatus!==5"
            >
                <list-item
                    label="审核说明："
                    :extra="refund.auditDesc"
                />
                <list-item
                    label="审核时间："
                    :extra="refund.auditTime|dateTime"
                />
                <list-item
                    extra="审核凭证："
                    class="reverse"
                >
                    <img-list
                        class="label"
                        slot="label"
                        :data="refund.auditAttachmentUrlList"
                    />
                </list-item>
            </list>
            <!--退货地址信息-->
            <list
                class="ma-t"
                v-if="showAddressInfo"
            >
                <list-item
                    label="退货信息："
                >
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <div>{{ refund.refundConsignee }} {{ refund.refundMobile }}</div>
                        <div>{{ refund.refundRegionName }} {{ refund.refundAddress }}</div>
                    </div>
                </list-item>
            </list>
            <!--物流信息-->
            <list
                class="ma-t"
                v-if="showExpressInfo"
            >
                <list-item
                    label="物流信息："
                >
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <div>{{ refund.expressName }}</div>
                        <div>{{ refund.trackingNumber }}</div>
                    </div>
                </list-item>
                <list-item
                    label="退货时间："
                    :extra="refund.refundTime | dateTime"
                />
            </list>
            <list
                class="ma-t"
                v-if="showReceiptInfo"
            >
                <list-item
                    label="收货时间："
                    :extra="refund.receiveTime | dateTime"
                />
            </list>
            <list
                class="ma-t"
                v-if="false"
            >
                <list-item
                    label="付款说明："
                    :extra="refund.paymentDesc"
                />
                <list-item
                    label="付款时间："
                    :extra="refund.paymentTime|dateTime"
                />
                <list-item
                    extra="付款凭证地址："
                    class="reverse"
                >
                    <img-list
                        class="label"
                        slot="label"
                        :data="refund.paymentAttachmentUrlList"
                    />
                </list-item>
            </list>
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import refundStatus from './_refundStatus';

export default {
    name: 'RefundDetail',
    mixins: [pageMixin],
    components: { refundStatus },
    data() {
        return {
            order: {},
            refund: {},
        };
    },
    computed: {
        isShopStore() {
            return this.$route.query.hasOwnProperty('isShopStore');
        },
        id() {
            return this.$route.params.id;
        },
        /**
         * 售后状态：1:待审核; 2:待打款; 3:已退款; 4:已关闭; 5:已取消; 6:待退货; 7:待收货
         * @returns {number}
         */
        refundStatus() {
            return +this.refund.refundStatus;
        },
        showAddressInfo() {
            return (
                this.refund.orderRefundType === 2 &&
                (this.refundStatus === 2 ||
                    this.refundStatus === 3 ||
                    this.refundStatus === 6 ||
                    this.refundStatus === 7)
            );
        },
        showExpressInfo() {
            return (
                this.refund.orderRefundType === 2 &&
                (this.refundStatus === 2 || this.refundStatus === 3 || this.refundStatus === 7)
            );
        },
        showReceiptInfo() {
            return this.refund.orderRefundType === 2 && (this.refundStatus === 2 || this.refundStatus === 3);
        },
        refundStatusName() {
            return this.refund.refundStatusName;
        },
        APIClass() {
            return this.isShopStore ? this.$api.Buyer.Sp.ShopStoreOrderRefund : this.$api.Buyer.Ex.OrderRefund;
        },
    },
    methods: {
        getData({ id = this.id } = {}) {
            return this.APIClass.detail({ id }).then(json => {
                const res = json.data.data;
                this.order = res.order;
                this.refund = res.orderRefund;
                return Promise.resolve(json);
            });
        },
        onCancelApply({ id = this.id } = {}) {
            return this.APIClass.cancelRefund({ id }).then(json => {
                const res = json.data;
                this.$messageBox.tips(res.msg);
                this.getData();
            });
        },
        onSubmitSuccess() {
            this.$nprogress.start();
            this.getData().then(() => {
                this.$nprogress.done();
            });
        },
    },
    created() {
        this.getData();
    },
};
</script>

<style lang="scss">
.footer {
    > .cart {
        color: $gray6;
        flex: 0 0 auto !important;
        width: auto !important;
        min-width: 1rem;
    }
}
</style>
<style lang="scss">
.refund-status {
    /*padding: 0.3rem 0;*/
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 0.36rem;
    > .f-icon {
        margin-right: $margin;
    }
}
</style>
