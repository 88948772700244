<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <list>
                <refund-item
                    v-for="(item) in listData"
                    :key="item.id"
                    :data="item"
                    :is-shop-store="isShopStore"
                />
            </list>
            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'RefundList',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            listData: [],
        };
    },
    computed: {
        isShopStore() {
            return this.$route.query.hasOwnProperty('isShopStore');
        },
        APIClass() {
            return this.isShopStore ? this.$api.Buyer.Sp.ShopStoreOrderRefund : this.$api.Buyer.Ex.OrderRefund;
        },
    },
    methods: {
        getListData({ refundType, refundStatus, currentPage, pageSize } = {}) {
            return this.APIClass.data({
                refundType,
                refundStatus,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.listData = this.listData.concat(res.data);
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss">
</style>
